import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import * as R from 'ramda'

import { EnsureRole } from '../../components/admin/ensure-role'

import { UserTable } from '../../components/users-table'

const USERS_TO_APPROVE = gql`
  query UsersToApprove($filter: UserFilter, $orderBy: [UsersOrderBy!]) {
    needsApproval(orderBy: $orderBy, filter: $filter) {
      nodes {
        city
        company
        createdAt
        email
        firstName
        id
        lastName
        phone
        priceLevel {
          id
          name
        }
        state
        website
      }
    }

    priceLevels {
      nodes {
        id
        name
      }
    }
  }
`

const ASSIGN_PRICE_LEVEL = gql`
  mutation AssignPriceLevel($input: UserPriceLevel!) {
    assignUserPriceLevel(input: $input) {
      message
    }
  }
`

const { NONE, ASC, DESC } = { NONE: null, ASC: 'asc', DESC: 'desc' }

const createOrderBy = (columnName) => (orderByDirection) => {
  return {
    orderBy: R.join('_', [R.toUpper(columnName), R.toUpper(orderByDirection)]),
  }
}

const changeDirection = (currentDirection) => {
  if (currentDirection === DESC) return ASC
  if (currentDirection === ASC) return NONE
  if (currentDirection === NONE) return DESC
}

const buildVariables = (field) => (orderByDirection) => {
  return field === null
    ? null
    : orderByDirection
    ? createOrderBy(field)(orderByDirection)
    : null
}

export default function UsersPage() {
  const { data, loading, refetch } = useQuery(USERS_TO_APPROVE)
  const [assignPriceLevel] = useMutation(ASSIGN_PRICE_LEVEL)
  const [orderByField, setOrderByField] = useState('created_at')
  const [orderByDirection, setOrderByDirection] = useState(DESC)

  useEffect(() => {
    const operation = async () => {
      const variables = buildVariables(orderByField)(orderByDirection)
      const updatedVariables = {
        ...variables,
        filter: { priceLevelId: { isNull: true } },
      }
      refetch(updatedVariables)
    }
    operation()
  }, [orderByField, orderByDirection])

  const handleSort = (field) => {
    const newDirection = changeDirection(orderByDirection)

    if (field !== orderByField) {
      setOrderByDirection(ASC)
    } else {
      setOrderByDirection(newDirection)
    }

    setOrderByField(field)
  }

  return (
    <EnsureRole
      userRole="client-admin"
      protectedContent={() => (
        <>
          <h2>Users (pending price level assignment)</h2>
          <UserTable
            assignPriceLevel={assignPriceLevel}
            loading={loading}
            onSort={handleSort}
            orderByField={orderByField}
            orderByDirection={orderByDirection}
            priceLevels={data && data.priceLevels && data.priceLevels.nodes}
            refetch={refetch}
            users={data && data.needsApproval && data.needsApproval.nodes}
          />
        </>
      )}
    />
  )
}
